@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Oswald:wght@200;300;400;500;600;700&display=swap');
:root{

    --background_content: #ebedf0 !important;
}
body{

}
.font_montserrat{
    font-family: "Montserrat",sans-serif !important;
}
.w_200{
    font-weight: 200 !important;
}
.w_300{
    font-weight: 300!important;
}
.w_400{
    font-weight: 400 !important;
}
.w_500{
    font-weight: 500 !important;
}
.w_600{
    font-weight: 600 !important;
}
.w_700{
    font-weight: 700 !important;
}

html,body{
    padding: 0;
    margin: 0;
    width: 100vw;
}
.home_header{
    height: 70px;
    padding: 0 10px 0 10px;
    border-bottom: 1.5px solid rgba(0,0,0,.2);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.sc-bdvvtL{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    align-self: center;

}
.file_upload_container{
    margin-top: 50px;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px dashed rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    cursor: pointer;
    background-color: rgba(131, 131, 131, 0.1);
    transition: all .2s;
    -webkit-box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
}
.file_upload_container:hover{
    background-color: rgba(131, 131, 131, 0.2);
    border: 2px dashed rgba(10, 10, 10, 0.2);
}
.header_buttons{
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
    height: 100%;
}
.header_buttons button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border: 0;
    outline: none;
    cursor: pointer;
}
.header_buttons button span{
    font-family: "Montserrat",sans-serif;
    font-weight: 700;
    font-size: 15px;

}
button.do_order{
    background-color: #23D160;
    color:white;
    transition: all .2s;
}
button.do_order:hover{
    background-color: #1ca44c;
}
button.group_edit{
    color:white;
    background-color: #118FE4;
    transition: all .2s;
}
button.group_edit:hover{
    background-color: #0e75ba;
}
button.info{
    transition: all .2s;
}
button.info:hover{
    color:blue;
}
.logo_head{
    height: 90%;
    margin-right: 10px;
}

.files{
    width: 80%;
    align-self: center;

    border-radius: 30px;
    min-height: 400px;
    -webkit-box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}
.file{
    margin: 10px;
    border-radius: 12px;
    width: auto;
    height: auto;
    border:1px solid #818c99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.file_buttons{
    width: 100%;
    padding: 10px 0 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.file_buttons button{
    border: 0;
    outline: none;
    background: rgba(0,0,0,0);
    transition: all .2s;
    padding: 5px;
    cursor: pointer;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}
.file_buttons button:hover{
    background: #e3e3e3;
}
.file_photo{
    align-self: center;
    /*flex:1;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px;
    -webkit-box-shadow: 2px 5px 16px 0px #d4d4d4, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 2px 5px 16px 0px #d4d4d4, 5px 5px 15px 5px rgba(0,0,0,0);
    align-items: center;
    border: 1px solid black;
}
.file_photo2{
    align-self: center;
    background-color: rgba(0,0,0,0);
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.file_photo .photo {
    width: 100%;
    height: 100%;
    /*position: absolute;*/

}
.file_down{
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    padding: 0 15px 15px 15px;
}
.file_down .Dropdown-root{
    margin-bottom: 8px;
}
.count_of_file{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: center;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 0;
    height: 30px !important;
}
.count_of_file button{
    background-color: #118DE1;
    position: relative;
    border:none;
    outline:none;
    cursor: pointer;
    transition: all .2s;
    height: 30px !important;
    width: 35px !important;
}
.count_of_file input{

    height: 30px !important;

}
.count_of_file button span{
    text-align: center !important;
    height: auto !important;
    left:50%;
    position: absolute;
    top:45%;
    transform: translate(-50%,-50%);
}
.count_of_file button:hover{
    background-color: #0e75ba;
}
.alert_block_upload{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.alert_block_upload .content{
    user-select: none;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: auto;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    padding: 35px 0 35px 0;
    -webkit-box-shadow: 2px 5px 16px 0px #484848, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 2px 5px 16px 0px #484848, 5px 5px 15px 5px rgba(0,0,0,0);
}
.alert_block_upload .content .alert_title{
    font-family: "Montserrat",sans-serif;
    font-weight: 900;
    font-size: 25px;
}
.file_progress{
    display: flex;
    margin-top: 50px;
    flex-direction: column-reverse;
    align-items: center;

}
.file_progress span{
    font-family: "Montserrat",sans-serif;
    font-weight: 500;
    font-size: 25px;
}
.alert_title#error{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}
.alert_title#error span{
    font-size: 22px;
    font-weight: 500;
}
.popout_button{
    justify-self: flex-end !important;
}
.cropik {
    border: 2px dashed black;
}
.cropik .ReactCrop__child-wrapper{
    height: 100%;
}
.cropik .ReactCrop__child-wrapper img{
    height: 100%;
}
.crop_buttons{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    width: 70%;
}
.crop_buttons button{
    margin: 5px;
}
.content.image_crop_content{
    max-height: 90vh;
    align-self: center;
    padding-top: 0 !important;
}
.no_crop_preview .ReactCrop__crop-selection{
    box-shadow: none;
    -webkit-box-shadow:none;
    border:none !important
}
.text_im{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
}
.insert_alert{
    background-color: rgba(0,0,0,.4);
    z-index: 22;
    top:0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
}
.border_text{

}
.settings{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 20px;
justify-content: center;
    align-content: center;
    align-items: flex-end;
    align-self: center;
    width: 80%;
}
.close_cropik{
    width: 40px;
    height: 40px;
    position: absolute;
    border: none;
    cursor: pointer;
    background-color: rgba(0,0,0,0);
    z-index: 222;
    align-self: center;
    outline: none;
}
.content.order_detail_content{
    width: 50%;
}
.content.order_detail_content{
    max-height: 100%;
    align-self: center;
    padding-top: 20px !important;
    -webkit-box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
}
.files_detail tr th {
    font-family: "Montserrat",sans-serif !important;
    font-weight: 700;
}
.files_detail{
    width: 90%;
    margin-top: 30px;
}
.detail_file{
    font-family: "Montserrat",sans-serif !important;
    font-weight: 500;
    text-align: center;
}
.detail_file td{
    margin-bottom: 15px;
}
.take_order_detail{
    width: 90%;

    margin-top: 20px;
}
.order_files_list{
    display: flex;
    border: 1px solid rgba(0,0,0,.5);
    padding: 0 10px 0 10px;
    margin-top: 5px;
    max-height: 100px;
    overflow-y: auto;
}
.horizontal_line{
    border-bottom: 1px dashed rgba(0,0,0,.5);
    width: 90%;
    margin-bottom: 10px;
}
.form_files{
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    align-content: stretch;
}
.form_files .vkuiTextarea{
    max-height: 120px !important;
}
.form_files .vkuiTextarea textarea{
    max-height: 120px !important;
}
.content.take_order_content{
    width: 70%;
}
.shop_div{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    padding: 0 16px 0 16px;
}
.shopik{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.loading_order{
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
}
.suc_cont_{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.do_order:disabled{
    background-color: rgba(0,0,0,.2);
    cursor: no-drop;
}
.do_order:disabled:hover{
    background-color: rgba(0,0,0,.2);
}
.photos_sort {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.photos_sort span{
    padding: 20px;
    background-color: rgba(200,200,200,.5);
}
.photos_sort .photos_labl{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.count_input{
    outline: none;
    border: none;
    height: 100%;
    text-align: center;
    width: 100%;
}
.default_inputs{
    display: flex;
    flex-direction: row;
    width: 350px;
}
.default_vls{
}
.c1c1c{
    width: 90%;
    align-self: center;
    text-align: center;
}
.top_subhead{
    position: absolute;
    transform: translate(0,-5px);
}
.d2{
    font-size: 13px !important;
}
.place_31{
}
.d31{
}
.d3 {
    font-size: 13px !important;
    white-space: nowrap;
    width: 100% !important;
    position: relative !important;
    align-self: center;
    border-radius: 20px;
}
.d53{
   width: 100%;
}

.d53{
    border-radius: 5px !important;
}
.d3 .Dropdown-placeholder.is-selected{
    overflow-x: hidden;
    max-width: 180px;
    text-overflow: ellipsis;
}
.Dropdown-menu{
    overflow-x: hidden;
}

.header_take_od{
    width: 90% !important;
}

@media screen and (max-width: 1000px) {
    /*.alert_block_upload:has(> div){
        justify-content: flex-start !important;
    }*/
    .alert_block_upload .content.take_order_content{
        margin-bottom: 10vh;
    }
    .take_order_content{
        width: 80vw !important;
        height: 80vh !important;
        display: flex;
        flex-direction: column;
        padding: 10px !important;

    }
    .take_order_detail{
        margin: 0 !important;
    }
    .header_take_od span{
        align-self: center;
        font-size: 25px !important;
    }
    .c1c1c{
        align-self: center;
        font-size: 13px !important;
    }

}

@media screen and (max-width: 670px) and (min-width: 501px) {
    .take_order_content .main_inputs{
        flex-wrap: wrap;
        flex-direction: column !important;
    }
    .take_order_content .form_files{
        flex-wrap: wrap !important;
        overflow-y: auto;
        overflow-x: hidden;
    }

}
@media screen and (max-width: 500px)  {
    .alert_block_upload .content{
        width: 95vw;

    }
    .crop_buttons{
        width: 90%;

    }
    .crop_buttons button{
        width: 45%;
    }
    .take_order_content{
        width: 90vw !important;
        height: 85vh !important;
        display: flex;
        align-self: center;
        justify-self: flex-start;
        flex-direction: column;
        padding: 10px !important;
    }
    .header_take_od span{
        align-self: center;
        font-size: 20px !important;
    }
     .c1c1c{
        align-self: center;
        font-size: 13px !important;
    }
     .sc_cr1{
         width: 90% !important;
     }
    .take_order_content .form_files{
        flex-wrap: wrap !important;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .take_order_content .main_inputs{
        flex-wrap: wrap;
        flex-direction: column !important;
    }
    .take_order_content .main_inputs div{

    }
    .select_sizes{
        max-height: 36px !important;
        white-space: nowrap;
        max-width: 150px;
    }
    .select_sizes .Dropdown-placeholder.is-selected{
        overflow-x: hidden;
    }
    .shop_div .shopik{
        flex-direction: column !important;

    }
    ._shop_info{
        margin-top: 10px;
    }
    .top_subhead{
        position: relative;
    }
    .default_inputs{
        flex-direction: column !important;
    }
    .default_inputs .d2{
        margin-top: 5px;
        max-width: 250px;
        width: 100% !important;
    }

}
.hdhdh{
    display: none;
    z-index: 999999999;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.8);
    flex-direction: column;
    pointer-events: none;
    justify-content: center;
}
.hdh_info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media screen and (orientation: landscape) and (min-width:650px ) and (max-height: 590px) {
    .hdhdh{
        display: flex !important;
    }
    .vkuiView__popout.View__popout{
        display: none !important;
    }
}
.pol_se h3{
    font-weight: 700 !important;
    font-size: 13px !important;
}
.pol_se {
    justify-content: center !important;
    font-weight: bold !important;
}
body{
    background: #ebedf0 !important;
}
.count_of_file{
    border-radius: 5px;
    outline: none;
    overflow: hidden;
}

/*
.main_inputs div{
    padding-right: 0!important;
    padding-left: 0!important;

}*/
.cropik .ReactCrop__child-wrapper{

}
@media screen and (max-width: 700px) {
    .slider_size{
        display: none;
    }
}