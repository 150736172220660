.admin_header{
    width: 100vw;
    height: 50px;
    background-color: rgba(0,0,0,.2);
    padding-left: 20px;
    display: flex;
    align-content: center;
    align-items: center;
}
.open_me{
    margin: 15px;
    width: 300px;
}
.admin_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.list_{
   width: 70%;
    display: flex;
    flex-direction: column;
}
.create_content{
    margin-top: 30px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.row_{
    border:1px solid rgba(0,0,0,.2);
    border-radius: 20px;
    width: 350px;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
}
.types_admin{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.delte_but{
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    color:red;
}
.select_op{
    height: 20px;
}
.selected_d{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px dashed black;
    width: 100%;
}
.this_dk{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
}
.content.add_type_content.size{
    width: 80vw;
}
.create_content{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.sizes_listing{
}
.size_cell{
    margin: 10px;
    border: 1px solid black;
}
.size_div{
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px dashed black;
    margin: 5px;
    padding-right: 5px;
    border-radius: 20px;
    width: 700px;
    justify-content: center;
}
.preview_panel_modal{
    width: 100vw;
    background-color: white;
    position: fixed;
    height: 100vh;
    z-index: 120;
    overflow-y: auto;
    overflow-x: hidden;
}
.header_prrev{
    padding: 10px;
    width: 100%;
    background-color: rgba(0,0,0,.2);
    display: flex;
    flex-direction: row;
    align-items: center;
}
.preview_div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 10px;
    justify-content: center;
}
.preview_photo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px;
    -webkit-box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 2px 5px 16px 0px #c4c4c4, 5px 5px 15px 5px rgba(0,0,0,0);
    padding: 20px;
    border-radius: 10px;
}
.prev_info{
    display: flex;
    flex-direction: column;
    width: 150px;
    justify-content: space-around;
    margin-left: 10px;
}
.preview_img_{
    border:1px dashed rgba(0,0,0,.3);
    align-self: center;
}
